import axios from 'axios'
/**
 * 高德地图根据ip定位获取地址
 * @returns {} ip定位地址
 */
export const getCurCityByIp = () => {
    return axios.get(
        "http://restapi.amap.com/v3/ip?key=9aef6d1d789a22d0afc2c13caa276e73"
    )
}
/**
 * 高德地图根据关键字获取位置信息列表
 * @param {String} keyword
 * @param {String} currentCity
 * @param {String} offset
 * @param {String} page
 * @returns {Object} 
 * 
 */
export const getLocByKeyWord = (keyword, currentCity, offset, page) => {
    return axios.get(
        `http://restapi.amap.com/v3/place/text?key=9aef6d1d789a22d0afc2c13caa276e73&keywords=${keyword}&city=${currentCity}&offset=${offset}&page=${page}`
    )
}
/**
 * 谷歌地图根据关键字获取位置信息
 * @param {String} address 
 * @returns 
 */
export const getLocByKeyWordGG = (address) => {
    return axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBiqnTIVrD2mgx4YiIpd3zq6ynQl7qYAE4`
    )
}