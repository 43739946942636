<!-- 用这个组件可以传el-input的参数 如placeholder -->
<template>
  <div class="main">
    <!-- 输入框 -->
    <el-input
      v-on="$listeners"
      v-bind="$attrs"
      type="textarea"
      resize="none"
      :ref="'contentInput'+index"
      :id="'contentInput'+index"
      :key="'contentInput'+index"
    >
    </el-input>
    <!-- 底部按钮 -->
    <!-- 定位显示 -->
    <div class="lcoation" v-show="isShowLoc">
      <svg class="icon" aria-hidden="true">
            <use 
             fill="#2d6cfc"
              href="#icon-weizhi"></use>
          </svg>
      <span>{{ selectedLocCity }}</span
      ><span class="close" @click="closeLoc">x</span>
    </div>
    <!-- 视频上传显示 -->
    <div class="lcoation" v-show="isShowVideo">
      <svg class="icon" aria-hidden="true">
            <use 
             fill="#2d6cfc"
              href="#icon-shipin"></use>
          </svg>
      <span>{{ videoFile.name }}</span
      ><span class="close" @click="closeVideo">x</span>
    </div>
    <!-- 链接显示 -->
    <div class="lcoation" v-show="isShowLink">
      <svg class="icon" aria-hidden="true">
            <use 
              fill="#2d6cfc"
              href="#icon-lianjie1"></use>
          </svg>
      <span>{{ link.name }}</span
      ><span class="close" @click="closeLink">x</span>
    </div>
    
    <!-- 定时选择 -->
    <div class="time" v-show="isShowTime">
      <div>
        <span>发布时间</span
        ><span
          ><el-date-picker
            v-model="date"
            type="date"
            :picker-options="pickerOptions0"
            placeholder="选择日期"
            @change="dateTimeChange"
          >
          </el-date-picker>
        </span>
        <span>
          <el-time-picker
            v-model="time"
            format="HH:mm"
            :picker-options="{
              selectableRange: `${new Date()} - 23:59:59`,
            }"
            placeholder="选择时间"
            @change="dateTimeChange"
          >
          </el-time-picker>
        </span>
      </div>
      <span class="close" @click="closeTime">x</span>
    </div>
    <!-- social link插入 -->
    <slot name="socialLink"></slot>
     <!-- 内容插槽 -->
    <slot name="content"></slot>
    <div class="btns">
      <div class="left-btns" style="display:flex;align-items: center;">
        <div class="img-btns">
        <!-- 表情 -->
        <div class="icon-margin emoji" v-if="isUseEmoji">
          <svg
            class="icon emoji"
            @click.stop="iconClick('emoji')"
            aria-hidden="true"
          >
            <use 
            href="#icon-charubiaoqing"></use>
          </svg>
          <!-- 表情包 -->
          <div class="emoji-list" v-show="isShowEmoji">
            <span
              v-for="(value, key, index) in emojiList"
              :key="key + index"
              @click.stop="emojiClick(value.char)"
              >{{ value.char }}</span
            >
          </div>
        </div>
        <!-- 图片 -->
        <div class="icon-margin" @click="iconClick('image')" v-if="isUseUpload">
          <svg class="icon" aria-hidden="true">
            <use 
           
              href="#icon-tupian"></use>
            </svg>
        </div>
        <!-- 视频 -->
        <div class="icon-margin video" @click="iconClick('video')" v-if="isUseVideo">
          <svg class="icon" aria-hidden="true">
            <use 
              href="#icon-shipin"></use>
          </svg>
          <input
            class="videoInput"
            ref="videoInput"
            title="请上传.mp4文件"
            type="file"
            accept=".mp4"
            @change="fileChange"
          />
        </div>
        <!-- 链接 -->
        <div class="icon-margin" @click="iconClick('link')" v-if="isUseLink">
          <svg class="icon" aria-hidden="true">
            <use 
            
              href="#icon-lianjie1"></use>
          </svg>
        </div>
        <!-- 定位 -->
        <div class="icon-margin" v-if="isUseLoc">
          <svg
            class="icon"
            aria-hidden="true"
            @click.stop="iconClick('location')"
          >
            <use 
             
              href="#icon-weizhi"></use>
          </svg>
          <!-- 定位选项列表 -->
          <div class="loc-list" v-show="isShowLocList" @click.stop="">
            <el-input
              placeholder="添加相关地点，获取更多曝光"
              v-model="locSearchCon"
              @input="searchLoc"
              key="locSearch"
            >
            </el-input>
            <!-- 列表 -->
            <div class="list" v-show="isShowLocList">
              <p
                @click.stop="clickLocItem(item)"
                v-for="item in locList"
                :key="item.id"
              >
                {{item.name||item.formatted_address}}
              </p>
            </div>
          </div>
        </div>
        
        </div>
        <!-- social link插槽 -->
        <slot name="linkBox"></slot>
        <!-- 按钮插槽 -->
        <slot name="btn"></slot>
      </div>
      <div class="seed-btn">
        <!-- 定时 -->
        <div class="icon-margin" @click="iconClick('time')" v-if="isUseTime">
          <svg class="icon" aria-hidden="true">
            <use 
             
              href="#icon-shijian"></use>
          </svg>
        </div>
        <el-button class="send" @click="send">{{seedText}}</el-button>
      </div>
    </div>
   
    <!-- 图片上传 -->
    <el-upload
      action="#"
      list-type="picture-card"
      :on-change="handleAvatarSuccess"
      :on-remove="handleRemove"
      :auto-upload="false"
      ref="upload"
      v-show="isShowUpload"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <!-- 图片展示 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <!-- 链接 -->
    <dynamic-add-link-alert :isShowLinkAlert='isShowLinkAlert' @shureLink="shureLink" @cancleLink="isShowLinkAlert=false"></dynamic-add-link-alert>
  </div>
</template>
<script>
import emojiList from "./emoji.json"; // 表情json文件
import { getCurCityByIp, getLocByKeyWord, getLocByKeyWordGG } from "./api";
import dynamicAddLinkAlert from "@/views/colleagueCircle/components/dynamic-addLinkAlert";
export default {
  name: "chatInput",
  props: {
    isUseLoc: {
      type: Boolean,
      default: true,
    }, //是否使用定位
    isUseTime: {
      type: Boolean,
      default: true,
    }, // 是否使用定时
    isUseEmoji: {
      type: Boolean,
      default: true,
    }, // 是否使用表情包
    isUseLink: {
      type: Boolean,
      default: true,
    }, // 是否使用添加链接
    isUseVideo: {
      type: Boolean,
      default: true,
    }, //是否使用添加视频
    isUseUpload: {
      type: Boolean,
      default: true,
    }, //是否使用上传图片
    isUseUploadApi: {
      type: Boolean,
      default: true,
    }, //是否使用上传图片功能
    seedText: {
      type: String,
      default: "发送",
    },
    contents: {
      type: String,
      default: "",
    },
    index: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      content: this.contents, //输入框内容
      videoFile: {}, //视频文件
      fileList: [], //上传图片内容
      pickerOptions0: {
        disabledDate(time) {
          let three = 24 * 3600 * 7 * 1000;
          return (
            time.getTime() < Date.now() - 8.64e7 ||
            time.getTime() > Date.now() + three
          );
        },
      },
      date: new Date(), // 日期选择器
      time: new Date(), // 时间选择器
      isShowLoc: false, //是否展示位置
      isShowTime: false, // 是否展示时间
      isShowEmoji: false, // 是否展示表情
      isShowLink: false, // 是否展示链接
      isShowVideo: false, //是否展示视频
      isShowLocList: false, //是否展示定位选项列表
      isShowUpload: false, //是否展示上传图片组件
      isShowLinkAlert: false, // 是否显示添加网址弹框
      page: 1, //定位选项列表当前页
      locSearchCon: "", //定位选项列表搜索内容
      currentCity: "", //当前城市
      locList: [], //选项列表
      mapObject: "", //地图实例对象
      selectedLocCity: "", //被选中城市
      selectedLoc: {
        lat: "",
        lng: "",
      }, //被选中坐标
      link: {}, //网址
      dialogImageUrl: "", //图片预览
      dialogVisible: false,
      disabled: false, //图片上传
      emojiList: "", //表情列表
      dateTime: "", //日期时间
      countryCode: this.$cookies.get("countryCode"),
    };
  },
  components: {
    dynamicAddLinkAlert,
  },
  mounted() {
    this.emojiList = emojiList;

    // 点击页面关闭下拉列表
    document.addEventListener("click", this.clickFn);
    // 监听addContentToChat改变消息框内容
    this.$bus.$on(`addContentToChat${this.index}`, this.addContentToChatCallback);
    // this.initMap();
  },
  computed: {
    startTime: {
      get() {
        return new Date().getHours();
      },
    },
  },
  methods: {
    addContentToChatCallback(content) {
      this.$refs['contentInput'+this.index].value += content;
      this.$emit("input", this.$refs['contentInput'+this.index].value);
    },
    clickFn(e) {
      let ids = e.target.id;
      this.isShowLocList = false;
      this.locSearchCon = "";
      if (!ids.includes("contentInput")) {
        this.isShowEmoji = false;
      }
    },
    // 关闭位置
    closeLoc() {
      this.isShowLoc = false;
      this.selectedLoc = {
        lat: "",
        lng: "",
      };
      this.$emit("locChange", this.selectedLoc);
    },
    // 关闭时间
    closeTime() {
      this.isShowTime = false;
      this.dateTime = ''
      this.$emit('dateTimeChange',this.dateTime)
    },
    // 删除上传视频
    closeVideo() {
      this.videoFile = {};
      this.isShowVideo = false;
      this.$refs.videoInput.value = "";
      this.$emit("videoChange", this.videoFile);
    },
    // 删除链接
    closeLink() {
      this.link = {
        name: "",
        url: "",
      };
      this.isShowLink = false;
      this.$emit("linkChange", this.link);
    },
    /**
     * @param type 图标类型
     * 图标点击事件
     */
    iconClick(type) {
      // 清除其它图标点击出现的弹框
      this.$emit("clearBtnDialog");
      switch (type) {
        case "emoji":
          this.isShowEmoji = true;
          break;
        case "image":
          // 是否使用原生上传图片功能
          if (!this.isUseUploadApi) {
            // 不使用将发出一个事件用于做别的操作
            this.$emit("uploadImageOther");
            return;
          }
          if (this.fileList.length == 0) {
            this.isShowUpload = !this.isShowUpload;
            return;
          }
          this.isShowUpload = true;
          break;
        case "video":
          break;
        case "link":
          this.isShowLinkAlert = true;
          break;
        case "location":
          // 国内环境获取当前城市，国外不能通过Ip获取定位
          if (this.countryCode == "CN") {
            this.getCurCity();
          }
          this.isShowLocList = true;
          break;
        case "time":
          this.isShowTime = true;
          this.dateTimeChange()
          break;
      }
    },
    // 视频上传
    fileChange(e) {
      // 没有选择文件
      if (e.target.files.length == 0) {
        this.isShowVideo = false;
        this.videoFile = {};
        return;
      }
      this.isShowVideo = true;
      this.videoFile = e.target.files[0];
      this.$emit("videoChange", this.videoFile);
    },
    // 表情包表情点击事件
    emojiClick(char) {
      this.$refs['contentInput'+this.index].focus();
      // this.$refs['contentInput'+this.index].value += char;
      // 获取el-input中的input元素
      let elInput =  this.$refs['contentInput'+this.index].$el.firstElementChild;

      // 获取el-input的值
      let txt = elInput.value;
      // 获取选区开始位置
      let startPos = elInput.selectionStart;
      // 获取选区结束位置
      let endPos = elInput.selectionEnd;
  
      if (startPos === undefined || endPos === undefined) return;
      // 将文本插入光标位置
      this.$refs['contentInput'+this.index].value = txt.substring(0, startPos) + char + txt.substring(endPos);
      elInput.selectionStart = startPos + char.length;
      elInput.selectionEnd = startPos + char.length;
      this.$emit("input", this.$refs['contentInput'+this.index].value);
    },
    // 获取当前城市
    async getCurCity() {
      const res = await getCurCityByIp();
      if (res.status == "200") {
        this.currentCity = res.data.city;
        this.searchLoc(this.currentCity);
      }
    },
    /**
     * 搜索地点
     */
    async searchLoc(keywords) {
      this.locList = [];
      // 国内
      if (this.countryCode == "CN") {
        const res = await getLocByKeyWord(keywords, this.currentCity, 10, 1);
        if (res.status == "200") {
          this.locList.push(...res.data.pois);
        }
      } else {
        // 海外
        const res = await getLocByKeyWordGG(keywords);
        if (res.data.status == "OK") {
          this.locList.push(...res.data.results);
        }
      }
    },
    /**
     * 点击地址列表选项
     */
    clickLocItem(item) {
      this.selectedLocCity = item.name || item.formatted_address;
      if (item.location) {
        // 国内
        this.selectedLoc.lat = item.location.split(",")[1];
        this.selectedLoc.lng = item.location.split(",")[0];
      } else {
        // 海外
        this.selectedLoc = item.geometry.location;
      }

      this.isShowLocList = false;
      this.isShowLoc = true;
      // 清空选项列表，搜索关键字
      this.locList = [];
      this.locSearchCon = "";
      this.$emit("locChange", this.selectedLoc);
    },
    // 添加网址弹框确认链接
    shureLink(linkName, linkUrl) {
      this.link.name = linkName;
      this.link.url = linkUrl;
      this.isShowLinkAlert = false;
      this.isShowLink = true;
      this.$emit("linkChange", this.link);
    },
    // 上传成功
    handleAvatarSuccess(file, fileList) {
      this.fileList = fileList;
      this.$emit("imgChange", fileList);
    },
    // 删除上传图片
    handleRemove(file) {
      let fileList = this.$refs.upload.uploadFiles;
      let index = fileList.findIndex((fileItem) => {
        return fileItem.uid === file.uid;
      });
      fileList.splice(index, 1);
      if (fileList.length == 0) {
        this.isShowUpload = false;
      }
      this.$emit("imgChange", fileList);
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 输入事件
    inputContent(content) {
      this.$refs['contentInput'+this.index].value += content;
      this.$emit("input", this.$refs['contentInput'+this.index].value);
    },
    // 日期选择
    dateTimeChange() {
      this.dateTime =
        this.date.getFullYear() +
        "-" +
        parseInt(this.date.getMonth()) +
        1 +
        "-" +
        this.date.getDate() +
        " " +
        this.time.getHours() +
        ":" +
        this.time.getMinutes();
      this.$emit('dateTimeChange',this.dateTime)
    },
    // 发送事件
    send() {
      const info = {
        inputContet: this.$refs['contentInput'+this.index].value,
        videoFile: this.videoFile,
        ImageList: this.fileList,
        time: this.time,
        link: this.link,
        location: {
          name: this.selectedLocCity,
          location: this.selectedLoc,
        },
      };
      this.$emit("send", info);
    },
  },
  beforeDestroy() {
    // 销毁
    this.$bus.$off(`addContentToChat${this.index}`, this.addContentToChatCallback);
    this.$bus.$off(`addContentToChat${this.index}`);
    document.removeEventListener("click", this.clickFn);
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-textarea__inner,
::v-deep .el-input__inner {
  border: 1px solid rgb(240, 240, 241);
  border-radius: 10px;
  background: rgb(240, 240, 241);
  &:focus {
    border: 1px solid #2d6cfc;
    background: #fff;
  }
}
.main {
  padding: 20px;
  background: #fff;

  & > div {
    margin: 10px 0px;
  }
  .time {
    color: #999;
    display: flex;
    align-items: center;
    div {
      span {
        margin-right: 10px;
      }
    }
  }
  .close {
    cursor: pointer;
  }
  .lcoation {
    padding: 5px 5px 5px 10px;
    color: #2d6cfc;
    display: flex;
    width: fit-content;
    background: rgb(240, 240, 241);
    border-radius: 8px;
    align-content: center;
    align-items: center;
    .icon {
      width: 14px;
      height: 14px;
      margin-right: 5px;
      cursor: none;
    }
    span {
      margin-right: 5px;
    }
  }
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-margin {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      margin-right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      position: relative;
      .icon {
        color: #838383;
      }
      &:hover {
        background: #d0deff;
        .icon {
          color: #2d6cfc;
        }
      }
      input {
        cursor: pointer;
      }
      .emoji-list {
        position: absolute;
        left: 0px;
        top: 40px;
        width: 300px;
        max-height: 250px;
        z-index: 1000;
        overflow: auto;
        border: 1px solid #ccc;
        background: #fff;
        border-radius: 5px;

        span {
          cursor: pointer;
          display: inline-block;
          margin: 5px;
          padding: 5px;
          width: 20px;
          &:hover {
            background: #ddd;
          }
        }
      }
      .loc-list {
        position: absolute;
        left: 0px;
        top: 40px;
        width: 400px;
        height: 300px;
        z-index: 1000;
        border: 1px solid #ccc;
        background: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        ::v-deep .el-input__inner {
          margin: 10px 20px;
          width: calc(100% - 40px);
          height: 40px;
        }
        .list {
          flex: 1;
          overflow: auto;
          p {
            padding: 5px 20px;
            cursor: pointer;
            &:hover {
              background: rgb(248, 248, 251);
            }
          }
        }
      }
    }
    .left-btns {
      display: flex;
      align-items: center;

      .video {
        position: relative;
        cursor: pointer;
        .videoInput {
          position: absolute;
          width: 22px;
          height: 22px;
          opacity: 0;
          cursor: pointer;
        }
      }
      .img-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }
    }

    .seed-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .send {
        border: none;
        background: #2d6cfc;
        border-radius: 15px;
        color: #fff;
        font-weight: bold;
        margin-left: 10px;
      }
    }
  }
  .icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
</style>